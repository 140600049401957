<template>
  <div class="row option">
    <div class="col-6">
      <div class="tooltip-box tooltip-box--price mb-2">
        <span class="badge-sale">30%</span>
        <!--<button
          class="btn-reset btn-tooltip"
          data-bs-toggle="tooltip"
          data-bs-html="true"
        >
          <i class="fas fa-info-circle"></i>
        </button>-->
      </div>
      <img
        src="https://image-service.loewe-zaun.de/production/350x/5fc63f3373510.png"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="col-6">
      <div class="option__name mb-2">Pergola für Wandmontage</div>
      <div class="option__price-box mb-2">
        <span class="option__price option__price--discount">€6,714</span>
        <span class="option__price option__price--old">€9,591</span>
      </div>
      <button class="btn btn-primary not-selected">Auswählen</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Simpleselect',
  setup () {}
}
</script>
